// This is used for sending the sub module type along with module type to the image process to find the corresponding prefix to add in API
const SubModulePrefix = {
  IconImage: 'IconImage',
  BackgroundImage: 'BackgroundImage',
  Multimedia: 'Multimedia',
  Presentation: 'Presentation',
};

export default Object.freeze(SubModulePrefix);

