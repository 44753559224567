export class VueModalPopUpViewModel {
  public static createEmpty(): VueModalPopUpViewModel {
    return new VueModalPopUpViewModel();
  }
  constructor(
    public Heading: string = '',
    public ModelDescription: string = '',
    public IsIconVisible: boolean = false,
    public IconURL: any = '',
    public Button1Text: string = '',
    public Button2Text: string = '',
  ) { }
}

