
import { VueModalPopUpViewModel } from '@/entities/VueModalPopUpViewModel';
import { Component, Vue, toNative, Prop} from 'vue-facing-decorator';
import VueModalPopUpButtonTextEnum from '@/enums/VueModalPopUpButtonTextEnum';
@Component
class VueModalPopUp extends Vue {
  @Prop() private showModalPopup?: boolean;
  @Prop() private modalPopUp?: VueModalPopUpViewModel;
  @Prop() private isContentLeftAligned?: boolean;
  private deleteBtn = VueModalPopUpButtonTextEnum[VueModalPopUpButtonTextEnum.Delete].toString();
  private Cancel(): void {
    // close modal pop up and emit to parent component
    this.$emit('close');
  }
  private Save() {
    // save modal pop up and emit to parent component
    this.$emit('save', this.modalPopUp);
  }
  private ShowHideSaveButton() {
    // show and hide btn
    return (this.modalPopUp!.Button2Text !== '' && this.modalPopUp!.Button2Text !== undefined);
  }
}
export default toNative(VueModalPopUp);
